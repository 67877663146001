<template>
    <div>
        <CoolLightBox
            :items="album_items"
            :index="album_index"
            @close="album_index = null"
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        >
        </CoolLightBox>
        <b-modal
            ref="customize_table_modal"
            centered
            :title="$t('customize_table')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-row" style="padding: 0 20px">
                            <div
                                class="form-group col-md-6"
                                v-for="(field, i) in table_fields"
                                :key="i"
                            >
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    aria-label="Checkbox for following text input"
                                    :value="field"
                                    :id="'f_' + field"
                                    v-model="table_selected_fields"
                                    style="vertical-align: middle;"
                                /><label
                                class="form-check-label"
                                :for="'f_' + field"
                                style="color: blue;"
                            >
                                {{ $t(field.toLowerCase()) }}</label
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal
            ref="reject_modal"
            centered
            :title="$t('reject')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="reject_prod_error || reject_prod_success ? true : false"
                        :variant="reject_prod_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="reject_prod_error = reject_prod_success = ''"
                    >
                        {{ reject_prod_error || reject_prod_success }}
                    </b-alert>
                    <div class="form-row ">
                        <div class="form-group col-md-12">
                            <label>{{ $t("rejection_reason") }}</label>
                            <textarea
                                type="text"
                                class="form-control"
                                :class="{ 'is-invalid': reject_prod_errors.rejection_reason }"
                                v-model="reject_prod_data.rejection_reason"
                            ></textarea>
                            <span class="error invalid-feedback">{{
                                    (reject_prod_errors.rejection_reason || []).join("-")
                                }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="reject_prod_disabled"
                        @click.prevent="rejectProduct"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="reject_prod_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <b-modal
            ref="accept_modal"
            centered
            :title="$t('accept')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block">
                <form class="col-md-12">
                    <b-alert
                        :show="accept_prod_error || accept_prod_success ? true : false"
                        :variant="accept_prod_error ? 'danger' : 'success'"
                        dismissible
                        @dismissed="accept_prod_error = accept_prod_success = ''"
                    >
                        {{ accept_prod_error || accept_prod_success }}
                    </b-alert>

                    {{ $t("are_you_sure") }}
                    <br/>

                    <div class="form-row" v-if="false">
                        <div class="form-group col-md-6">
                            <label>{{ $t("weight") }}</label>
                            <input
                                type="number"
                                min="0"
                                class="form-control"
                                :class="{ 'is-invalid': accept_prod_errors.weight }"
                                v-model="accept_prod_data.weight"
                            />
                            <span class="error invalid-feedback">{{
                                    (accept_prod_errors.weight || []).join("-")
                                }}</span>
                        </div>
                        <div class="form-group col-md-6">
                            <label>{{ $t("dimension") }}</label>
                            <select
                                class="form-control pt-0"
                                :class="{ 'is-invalid': accept_prod_errors.dimension_id }"
                                v-model="accept_prod_data.dimension_id"
                            >
                                <option value="" disabled>{{ $t("select") }}</option>
                                <option
                                    v-for="(dimension, i) in dimensions"
                                    :key="i"
                                    :value="dimension.id"
                                >
                                    {{ dimension.d_length }} X {{ dimension.d_width }} X
                                    {{ dimension.d_height }}
                                </option>
                            </select>
                            <span class="error invalid-feedback">{{
                                    (accept_prod_errors.dimension_id || []).join("-")
                                }}</span>
                        </div>
                    </div>

                    <button
                        type="submit"
                        class="btn btn-info mt-2"
                        :disabled="accept_prod_disabled"
                        @click.prevent="acceptProduct"
                    >
                        {{ $t("save") }}
                        <b-spinner small v-if="accept_prod_disabled"></b-spinner>
                    </button>
                </form>
            </div>
        </b-modal>

        <b-modal
            ref="view_product_modal"
            centered
            :title="$t('view')"
            header-class="new_modal_header"
            hide-footer
        >
            <div class="d-block" v-if="product.id">
                <div class="form-row">
                    <div class="form-group col-12">
                        <div class="form-group">
                            <div class="image_div">
                                <img
                                    :src="
                    image_url + '/' + product.image || '/images/placeholder.jpg'
                  "
                                    @error="$event.target.src = '/images/placeholder.jpg'"
                                    alt="product view"
                                />
                            </div>
                        </div>
                        <div class="from-group col-md">
                            <h4 class="mb-1">{{ product["name_" + $i18n.locale] }}</h4>
                            <p class="m-0">{{ product["desc_" + $i18n.locale] }}</p>
                        </div>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">{{ $t("price") }}</label>
                        <p class="m-0">{{ product.price || 0 }} {{ $t("sar") }}</p>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">{{ $t("qty") }}</label>
                        <p class="m-0">
                            {{ product.qty ? product.qty : $t("is_unlimited") }}
                        </p>
                    </div>
                    <div class="form-group col-md-12">
                        <label class="form-label">{{ $t("store") }}</label>
                        <p class="m-0">
                            {{ (product.store || {}).store_name }}
                        </p>
                    </div>
                    <div
                        class="form-group col-md-6"
                        v-if="(product.options || []).length !== 0"
                    >
                        <label class="form-label">{{ $t("options") }}</label>
                        <p class="m-0">
                            <b-badge v-for="(item, index) in product.options" :key="index"
                            >{{ item["name_" + $i18n.locale] }}
                                <span v-if="item.values.length !== 0"
                                >({{ item.values.length || 0 }})</span
                                >
                            </b-badge>
                        </p>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">{{ $t("categories") }}</label>
                        <p class="m-0">
                            <b-badge
                                v-for="(item, index) in product.categories"
                                :key="index"
                            >{{ item["name_" + $i18n.locale] }}
                            </b-badge
                            >
                        </p>
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">{{ $t("sub_categories") }}</label>
                        <p class="m-0">
                            <b-badge
                                v-for="(item, index) in product.sub_categories"
                                :key="index"
                            >{{ item["name_" + $i18n.locale] }}
                            </b-badge
                            >
                        </p>
                    </div>
                    <div class="form-group col-md-12" v-if="product.rejection_reason">
                        <label class="form-label">{{ $t("c_reason") }}</label>
                        <p class="m-0">{{ product.rejection_reason }}</p>
                    </div>
                    <div class="form-group col-md-12 actions-group">
                        <button
                            v-if="
                $can('edit products') &&
                  (product.status == 'pending' || product.status == 'rejected')
              "
                            class="btn btn-primary btn-sm"
                            @click="acceptProduct(product.id)"
                        >
                            <b-spinner small v-if="disabled_s[i]"></b-spinner>
                            <i class="fas fa-check" v-else></i>
                            {{ $t("accept") }}
                        </button>

                        <button
                            v-if="$can('edit products') && product.status == 'pending'"
                            class="btn btn-danger btn-sm"
                            @click="showRejectProduct(product.id)"
                        >
                            {{ $t("reject") }}
                        </button>

                        <router-link
                            v-if="$can('edit products')"
                            :to="'/product/' + product.id"
                            class="btn btn-info btn-sm"
                        >
                            {{ $t("edit") }}
                        </router-link>
                        <button
                            v-if="$can('delete products')"
                            @click="deleteProduct(product.id)"
                            class="btn btn-danger btn-sm"
                        >
                            {{ $t("delete") }}
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <br/>
                        <b-tabs
                            pills
                            card
                            no-key-nav
                            content-class="custom-card"
                            nav-wrapper-class="custom-nav-card"
                        >
                            <b-tab no-body :title="$t('all')" active>
                                <template v-slot:title>
                                    <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body class="card">
                                        <b-card-header>
                                            <div class="card-tools">
                                                <div class="input-group input-group-sm">
                                                    <i
                                                        class="fas fa-cog c_pointer customize_table"
                                                        :title="$t('customize_table')"
                                                        @click="customizeTable"
                                                    ></i>
                                                    <input
                                                        type="text"
                                                        class="form-control float-right"
                                                        :placeholder="$t('search')"
                                                        v-model="search_key"
                                                    />
                                                    <div class="input-group-append">
                                                        <button
                                                            type="submit"
                                                            :disabled="search_disabled"
                                                            @click.prevent="searchAll"
                                                            class="btn btn-default"
                                                        >
                                                            <i
                                                                class="fa fa-spinner fa-spin"
                                                                v-if="search_disabled"
                                                            ></i>
                                                            <i class="fas fa-search" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <!-- /.card-header -->
                                        <b-card-body class="table-responsive p-0">
                                            <table class="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th v-if="table_selected_fields.includes('#')">
                                                        #
                                                    </th>
                                                    <th v-if="table_selected_fields.includes('image')">
                                                        {{ $t("image") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('name')"
                                                        class="sort"
                                                        @click="sortTable('name_' + $i18n.locale)"
                                                    >
                                                        {{ $t("name") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('store')"
                                                        class="sort"
                                                        @click="sortTable('store_id')"
                                                    >
                                                        {{ $t("store") }}
                                                    </th>
                                                    <th v-if="table_selected_fields.includes('vendor')">
                                                        {{ $t("vendor") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('category')"
                                                        class="sort"
                                                        @click="sortTable('category_id')"
                                                    >
                                                        {{ $t("categories") }}
                                                    </th>
                                                    <th
                                                        v-if="
                                table_selected_fields.includes('sub_category')
                              "
                                                        class="sort"
                                                        @click="sortTable('sub_category_id')"
                                                    >
                                                        {{ $t("sub_categories") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('price')"
                                                        class="sort"
                                                        @click="sortTable('price')"
                                                    >
                                                        {{ $t("price") }}
                                                    </th>
                                                    <th
                                                        v-if="
                                table_selected_fields.includes('prepared_time')
                              "
                                                        class="sort"
                                                        @click="sortTable('prepared_time')"
                                                    >
                                                        {{ $t("prepared_time") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('qty')"
                                                        class="sort"
                                                        @click="sortTable('qty')"
                                                    >
                                                        {{ $t("quantity") }}
                                                    </th>
                                                    <th
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                        class="sort"
                                                        @click="sortTable('created_at')"
                                                    >
                                                        {{ $t("created_at") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('in_stock')"
                                                        class="sort"
                                                        @click="sortTable('in_stock')"
                                                    >
                                                        {{ $t("in_stock") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('status')"
                                                        class="sort"
                                                        @click="sortTable('status')"
                                                    >
                                                        {{ $t("status") }}
                                                    </th>
                                                    <th
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        {{ $t("actions") }}
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        v-if="!load"
                                                    >
                                                        <i class="fa fa-sync  fa-spin load-con"></i>
                                                    </td>
                                                    <td
                                                        :colspan="table_selected_fields.length"
                                                        class="no_data"
                                                        v-if="load && products.length == 0"
                                                    >
                                                        {{ $t("no_data") }}
                                                    </td>
                                                </tr>
                                                <tr
                                                    v-for="(item, i) in products"
                                                    :key="i"
                                                    v-show="load"
                                                >
                                                    <td v-if="table_selected_fields.includes('#')">
                                                        {{ i + 1 }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('image')">
                                                        <img
                                                            @click="showProductAlbum(item.id)"
                                                            :src="image_url + '/' + item.image"
                                                            @error="
                                  $event.target.src = '/images/placeholder.jpg'
                                "
                                                        />
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('name')">
                                                        {{ item["name_" + $i18n.locale] }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('store')">
                                                        {{ (item.store || {})["store_name"] }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('vendor')">
                                                        <router-link
                                                            :to="'/vendors/' + (item.store || {}).vendor.id"
                                                            v-tooltip="$t('view')"
                                                        >
                                                            {{ (item.store || {}).vendor["name"] }}
                                                        </router-link>
                                                    </td>
                                                    <td
                                                        v-if="table_selected_fields.includes('category')"
                                                    >
                                                        {{
                                                            (item.categories || [])
                                                                .map(e => e["name_" + $i18n.locale])
                                                                .join("-")
                                                        }}
                                                    </td>
                                                    <td
                                                        v-if="
                                table_selected_fields.includes('sub_category')
                              "
                                                    >
                                                        {{
                                                            (item.sub_categories || [])
                                                                .map(e => e["name_" + $i18n.locale])
                                                                .join("-") || "-"
                                                        }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('price')">
                              <span v-if="item.discount_value">
                                <strike class="ml-1">
                                  {{ item["price"] }}
                                </strike>
                                <b v-if="item.discount_type == 'percentage'">
                                  {{
                                        (
                                            item.price -
                                            (item.discount_value / 100) * item.price
                                        ).toFixed(2)
                                    }}
                                </b>
                                <b v-if="item.discount_type == 'fixed'">
                                  {{ item.price - item.discount_value }}
                                </b>
                              </span>
                                                        <span v-else>
                                <!-- {{ item["price"] }} -->
                                                            <!-- {{
                                                              item["price"] && item["price"] === 0
                                                                ? $t("special_order")
                                                                : item["price"]
                                                            }} -->
                                {{
                                                                item["price"] === 0
                                                                    ? $t("special_order")
                                                                    : item["price"]
                                                            }}
                              </span>
                                                    </td>
                                                    <td
                                                        v-if="
                                table_selected_fields.includes('prepared_time')
                              "
                                                    >
                                                        {{ item.prepared_time || "-" }} {{ $t("day") }}
                                                    </td>
                                                    <td v-if="table_selected_fields.includes('qty')">
                                                        {{ item.qty }}
                                                    </td>

                                                    <td
                                                        v-if="
                                table_selected_fields.includes('created_at')
                              "
                                                        v-html="dateTimeFormat(item.created_at)"
                                                    ></td>
                                                    <td
                                                        class="pb-0"
                                                        v-if="table_selected_fields.includes('in_stock')"
                                                    >
                                                        <label class="switch mr-2 ml-2">
                                                            <input
                                                                type="checkbox"
                                                                :disabled="!$can('edit products')"
                                                                v-model="item.in_stock"
                                                                :true-value="'1'"
                                                                :false-value="'0'"
                                                                @change="
                                    $can('edit products')
                                      ? changeInStock(item.id)
                                      : false
                                  "
                                                            />
                                                            <div class="slider round y_n_switch">
                                                                <span class="on">{{ $t("yes") }}</span>
                                                                <span class="off">{{ $t("no") }}</span>
                                                            </div>
                                                        </label>
                                                    </td>

                                                    <td v-if="table_selected_fields.includes('status')">
                              <span
                                  v-if="item.status == 'pending'"
                                  class="badge badge-info"
                              >{{ $t(item.status) }}
                              </span>

                                                        <span
                                                            v-tooltip="item.rejection_reason"
                                                            v-else-if="item.status == 'rejected'"
                                                            class="badge badge-danger"
                                                        >{{ $t(item.status) }}
                              </span>

                                                        <label class="switch mr-2 ml-2" v-else>
                                                            <input
                                                                type="checkbox"
                                                                :disabled="!$can('edit products')"
                                                                v-model="item.status"
                                                                :true-value="'on'"
                                                                :false-value="'off'"
                                                                @change="
                                    $can('edit products')
                                      ? changeStatus(item.id)
                                      : false
                                  "
                                                            />
                                                            <div class="slider round">
                                                                <span class="on">{{ $t("on") }}</span>
                                                                <span class="off">{{ $t("off") }}</span>
                                                            </div>
                                                        </label>
                                                    </td>
                                                    <td
                                                        class="actions-group"
                                                        v-if="table_selected_fields.includes('actions')"
                                                    >
                                                        <button
                                                            class="btn btn-sm btn-info"
                                                            v-tooltip="$t('view')"
                                                            @click="viewProduct(item.id)"
                                                        >
                                                            <i class="fas fa-eye"></i>
                                                        </button>
                                                        <button
                                                            v-if="
                                  $can('edit products') &&
                                    (item.status == 'pending' ||
                                      item.status == 'rejected')
                                "
                                                            class="btn btn-primary btn-sm"
                                                            v-tooltip="$t('accept')"
                                                            @click="acceptProduct(item.id)"
                                                        >
                                                            <b-spinner small v-if="disabled_s[i]">
                                                            </b-spinner>
                                                            <i class="fas fa-check" v-else></i>
                                                        </button>

                                                        <button
                                                            v-if="
                                  $can('edit products') &&
                                    item.status == 'pending'
                                "
                                                            class="btn btn-danger btn-sm"
                                                            v-tooltip="$t('reject')"
                                                            @click="showRejectProduct(item.id)"
                                                        >
                                                            <i class="fas fa-exclamation-triangle"></i>
                                                        </button>

                                                        <router-link
                                                            v-if="$can('edit products')"
                                                            :to="'/product/' + item.id"
                                                            class="btn btn-info btn-sm"
                                                            v-tooltip="$t('edit')"
                                                        >
                                                            <i class="fas fa-pencil-alt"> </i>
                                                        </router-link>
                                                        <button
                                                            v-if="$can('delete products')"
                                                            @click="deleteProduct(item.id)"
                                                            class="btn btn-danger btn-sm"
                                                            v-tooltip="$t('delete')"
                                                        >
                                                            <i class="fas fa-trash"> </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                        <b-card-footer class="card-footer clearfix">
                                            <b-pagination
                                                class="m-2"
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                :prev-text="$t('previous')"
                                                :next-text="$t('next')"
                                                align="right"
                                                :first-number="true"
                                                :last-number="true"
                                            ></b-pagination>
                                        </b-card-footer>
                                    </b-card>
                                </b-card-text>
                            </b-tab>
                            <b-tab no-body :title="$t('add')" v-if="$can('add products')">
                                <template v-slot:title>
                                    <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                                </template>
                                <b-card-text>
                                    <b-card no-body>
                                        <b-card-body>
                                            <form class="col-md-8">
                                                <b-alert
                                                    :show="add_error || add_success ? true : false"
                                                    :variant="add_error ? 'danger' : 'success'"
                                                    dismissible
                                                    @dismissed="add_error = add_success = ''"
                                                >
                                                    {{ add_error || add_success }}
                                                </b-alert>

                                                <div>
                                                    <b-tabs content-class="mt-3" no-key-nav>
                                                        <b-tab
                                                            :title="$t('info')"
                                                            active
                                                            :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'image_input',
                                    'name_ar',
                                    'name_en',
                                    'desc_ar',
                                    'desc_en',
                                    'category_id',
                                    'size_id',
                                    'sku',
                                    'qty',
                                    'price',
                                    'prepared_time',
                                    'weight',
                                    'dimension_id'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                                                        >
                                                            <div class="form-row ">
                                                                <div class="form-group col-md-12">
                                                                    <label>{{ $t("image") }}</label>

                                                                    <div class="image_div" v-if="add_data_image">
                                                                        <img :src="add_data_image"/>
                                                                        <i
                                                                            class="fa fa-times"
                                                                            @click.prevent="
                                        add_data_image = null;
                                        add_data_icon = null;
                                      "
                                                                        ></i>
                                                                    </div>
                                                                    <b-form-file
                                                                        v-else
                                                                        :placeholder="$t('choose_file')"
                                                                        accept="image/*"
                                                                        @change.prevent="
                                      onImageChange($event, 'add_data')
                                    "
                                                                        ref="add_data_icon"
                                                                    ></b-form-file>
                                                                    <span
                                                                        class="error invalid-feedback d-block"
                                                                    >{{
                                                                            (add_errors.image_input || []).join("-")
                                                                        }}</span
                                                                    >
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("vendor") }}</label>
                                                                    <select
                                                                        class="form-control custom-select"
                                                                        :class="{
                                      'is-invalid': add_errors.vendor_id
                                    }"
                                                                        v-model="add_data.vendor_id"
                                                                    >
                                                                        <option selected="" disabled="" value="">{{
                                                                                $t("select")
                                                                            }}
                                                                        </option>
                                                                        <option
                                                                            v-for="(vendor, i) in vendors"
                                                                            :key="i"
                                                                            :value="vendor.id"
                                                                        >{{ vendor["name"] }}
                                                                        </option
                                                                        >
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.vendor_id || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("store") }}</label>
                                                                    <select
                                                                        :disabled="
                                      !add_data.vendor_id || stores.length == 0
                                    "
                                                                        class="form-control custom-select"
                                                                        :class="{
                                      'is-invalid': add_errors.store_id
                                    }"
                                                                        v-model="add_data.store_id"
                                                                    >
                                                                        <option selected="" disabled="" value="">{{
                                                                                $t("select")
                                                                            }}
                                                                        </option>
                                                                        <option
                                                                            v-for="(store, i) in stores"
                                                                            :key="i"
                                                                            :value="store.id"
                                                                        >{{ store["store_name"] }}
                                                                        </option
                                                                        >
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.store_id || []).join("-")
                                                                        }}</span>
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label
                                                                    >{{ $t("name") }} | {{ $t("ar") }}</label
                                                                    >
                                                                    <input
                                                                        type="text"
                                                                        class="form-control rtl"
                                                                        :class="{
                                      'is-invalid': add_errors.name_ar
                                    }"
                                                                        v-model="add_data.name_ar"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.name_ar || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label
                                                                    >{{ $t("name") }} | {{ $t("en") }}</label
                                                                    >
                                                                    <input
                                                                        type="text"
                                                                        class="form-control ltr"
                                                                        :class="{
                                      'is-invalid': add_errors.name_en
                                    }"
                                                                        v-model="add_data.name_en"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.name_en || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label
                                                                    >{{ $t("desc") }} | {{ $t("ar") }}</label
                                                                    >
                                                                    <textarea
                                                                        class="form-control rtl"
                                                                        :class="{
                                      'is-invalid': add_errors.desc_ar
                                    }"
                                                                        rows="4"
                                                                        v-model="add_data.desc_ar"
                                                                    ></textarea>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.desc_ar || []).join("-")
                                                                        }}</span>
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label
                                                                    >{{ $t("desc") }} | {{ $t("en") }}</label
                                                                    >
                                                                    <textarea
                                                                        class="form-control ltr"
                                                                        :class="{
                                      'is-invalid': add_errors.desc_en
                                    }"
                                                                        rows="4"
                                                                        v-model="add_data.desc_en"
                                                                    ></textarea>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.desc_en || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-12">
                                                                    <div class="icheck-primary">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="special"
                                                                            id="special"
                                                                            v-model="add_data.is_special_order"
                                                                            :true-value="1"
                                                                            :false-value="0"
                                                                        />
                                                                        <label for="special">{{
                                                                                $t("special_order")
                                                                            }}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group col-md-12">
                                                                    <label>{{ $t("categories") }}</label>
                                                                    <multiselect
                                                                        selectLabel=""
                                                                        selectedLabel=""
                                                                        deselectLabel=""
                                                                        v-model="add_data.categories_ids"
                                                                        :placeholder="
                                      $t('select') + ' ' + $t('categories')
                                    "
                                                                        :multiple="true"
                                                                        :searchable="true"
                                                                        :internal-search="true"
                                                                        :options-limit="300"
                                                                        :show-no-results="false"
                                                                        :hide-selected="true"
                                                                        :options="categories"
                                                                        track-by="id"
                                                                        :label="'name_' + $i18n.locale"
                                                                        :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                                                                    >
                                                                    </multiselect>
                                                                    <span
                                                                        class="error invalid-feedback d-block"
                                                                    >{{
                                                                            (add_errors.categories_ids || []).join(
                                                                                "-"
                                                                            )
                                                                        }}</span
                                                                    >
                                                                </div>

                                                                <div
                                                                    class="form-group col-md-12"
                                                                    v-if="add_data.is_special_order === 0"
                                                                >
                                                                    <label>{{ $t("sub_categories") }}</label>
                                                                    <multiselect
                                                                        selectLabel=""
                                                                        selectedLabel=""
                                                                        deselectLabel=""
                                                                        v-model="add_data.sub_categories_ids"
                                                                        :placeholder="
                                      $t('select') + ' ' + $t('sub_categories')
                                    "
                                                                        :multiple="true"
                                                                        :searchable="true"
                                                                        :internal-search="true"
                                                                        :options-limit="300"
                                                                        :show-no-results="false"
                                                                        :hide-selected="true"
                                                                        :group-select="true"
                                                                        :options="sub_categories"
                                                                        group-values="sub_categories"
                                                                        group-label="category"
                                                                        track-by="id"
                                                                        :label="'name_' + $i18n.locale"
                                                                        :custom-label="
                                      option => {
                                        return option['name_' + $i18n.locale];
                                      }
                                    "
                                                                    >
                                                                    </multiselect>
                                                                    <span
                                                                        class="error invalid-feedback d-block"
                                                                    >{{
                                                                            (
                                                                                add_errors.sub_categories_ids || []
                                                                            ).join("-")
                                                                        }}</span
                                                                    >
                                                                </div>


                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("brands") }}</label>
                                                                    <select
                                                                        class="form-control custom-select"
                                                                        :class="{ 'is-invalid': add_errors.brand_id }"
                                                                        v-model="add_data.brand_id"
                                                                    >
                                                                        <option selected="" value="">{{
                                                                                $t("select")
                                                                            }}
                                                                        </option>
                                                                        <option
                                                                            v-for="(brand, i) in brands"
                                                                            :key="i"
                                                                            :value="brand.id"
                                                                        >{{ brand["name_"+$i18n.locale] }}
                                                                        </option
                                                                        >
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.brand_id || []).join("-")
                                                                        }}</span>
                                                                </div>


                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("made in") }}</label>
                                                                    <select
                                                                        class="form-control custom-select"
                                                                        :class="{ 'is-invalid': add_errors.made_in_country_id }"
                                                                        v-model="add_data.made_in_country_id"
                                                                    >
                                                                        <option selected="" value="">{{
                                                                                $t("select")
                                                                            }}
                                                                        </option>
                                                                        <option
                                                                            v-for="(country, i) in countries"
                                                                            :key="i"
                                                                            :value="country.id"
                                                                        >{{ country["name_"+$i18n.locale] }}
                                                                        </option
                                                                        >
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.made_in_country_id || []).join("-")
                                                                        }}</span>
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("offers") }}</label>
                                                                    <select
                                                                        class="form-control custom-select"
                                                                        :class="{ 'is-invalid': add_errors.offer_id }"
                                                                        v-model="add_data.offer_id"
                                                                    >
                                                                        <option selected="" value="">{{
                                                                                $t("select")
                                                                            }}
                                                                        </option>
                                                                        <option
                                                                            v-for="(offer, i) in offers"
                                                                            :key="i"
                                                                            :value="offer.id"
                                                                        >{{ offer["name_"+$i18n.locale] }}
                                                                        </option
                                                                        >
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.offer_id || []).join("-")
                                                                        }}</span>
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("sku") }}</label>
                                                                    <input
                                                                        type="text"
                                                                        class="form-control ltr"
                                                                        :class="{ 'is-invalid': add_errors.sku }"
                                                                        v-model="add_data.sku"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.sku || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("quantity") }}</label>
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        class="form-control ltr"
                                                                        :class="{ 'is-invalid': add_errors.qty }"
                                                                        v-model="add_data.qty"
                                                                        :disabled="add_data.is_unlimited === 1"
                                                                        :readonly="add_data.is_unlimited === 1"
                                                                    />
                                                                    <div class="col-md-12 px-0 mt-2">
                                                                        <div class="icheck-primary">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="unlimited"
                                                                                id="unlimited"
                                                                                v-model="add_data.is_unlimited"
                                                                                :true-value="1"
                                                                                :false-value="0"
                                                                            />
                                                                            <label for="unlimited">{{
                                                                                    $t("is_unlimited")
                                                                                }}</label>
                                                                        </div>
                                                                    </div>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.qty || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div
                                                                    class="form-group col-md-6"
                                                                    v-if="add_data.is_special_order === 0"
                                                                >
                                                                    <label
                                                                    >{{ $t("price") }} ({{ $t("sar") }})</label
                                                                    >
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        class="form-control ltr"
                                                                        :class="{ 'is-invalid': add_errors.price }"
                                                                        v-model="add_data.price"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.price || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label
                                                                    >{{ $t("prepared_time") }} ({{
                                                                            $t("day")
                                                                        }})</label
                                                                    >
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        class="form-control ltr"
                                                                        :class="{
                                      'is-invalid': add_errors.prepared_time
                                    }"
                                                                        v-model="add_data.prepared_time"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.prepared_time || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                            </div>
                                                        </b-tab>
                                                        <b-tab
                                                            :title="$t('options')"
                                                            :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                                                        >
                                                            <div
                                                                class="col-md-12 text-center"
                                                                style="color:red;marginBottom:40px"
                                                                v-if="
                                  add_data.categories_ids
                                    ? add_data.categories_ids.length === 0
                                    : false
                                "
                                                            >
                                                                {{ $t("firstly_select_category") }}
                                                            </div>
                                                            <div
                                                                class="col-md-8 text-center"
                                                                style="color:red;marginBottom:40px"
                                                                v-else-if="category_options.length == 0"
                                                            >
                                                                {{ $t("no_options_found") }}
                                                            </div>
                                                            <div class="form-row col-md-12" v-else>
                                                                <div
                                                                    class="col-md-12"
                                                                    v-for="(add_option, m) in add_data.options"
                                                                    :key="m"
                                                                >
                                                                    <div class="form-group col-12">
                                                                        <label>{{ $t("image") }}</label>
                                                                        <div
                                                                            class="image_div"
                                                                            v-if="add_data.options[m].image"
                                                                        >
                                                                            <img
                                                                                :src="
                                          convertImage(
                                            add_data.options[m].image
                                          )
                                        "
                                                                            />

                                                                            <i
                                                                                v-if="add_data.options[m].image"
                                                                                class="fa fa-times"
                                                                                @click.prevent="
                                          add_data.options[m].image = null
                                        "
                                                                            ></i>
                                                                        </div>
                                                                        <b-form-file
                                                                            @change="
                                        add_data.options[m].image =
                                          $event.target.files[0]
                                      "
                                                                        ></b-form-file>
                                                                    </div>
                                                                    <div class="input-group mb-3">
                                                                        <select
                                                                            class="form-control pt-0"
                                                                            v-model="add_data.options[m].option_id"
                                                                            :class="{
                                        'is-invalid':
                                          add_errors[
                                            'options.' + m + '.option_id'
                                          ]
                                      }"
                                                                        >
                                                                            <option disabled value="">{{
                                                                                    $t("option")
                                                                                }}
                                                                            </option>
                                                                            <option
                                                                                v-for="(option, i) in m > 0
                                          ? filter_category(
                                              updated_categories,
                                              m
                                            )
                                          : category_options"
                                                                                :value="option.id"
                                                                                :selected="
                                          category_options.length === 1 &&
                                            'selected'
                                        "
                                                                                :key="i"
                                                                            >{{
                                                                                    option["name_" + $i18n.locale]
                                                                                }}
                                                                            </option
                                                                            >
                                                                        </select>
                                                                        <select
                                                                            class="form-control pt-0"
                                                                            v-model="
                                        add_data.options[m].option_value_id
                                      "
                                                                            :class="{
                                        'is-invalid':
                                          add_errors[
                                            'options.' + m + '.option_value_id'
                                          ]
                                      }"
                                                                        >
                                                                            <option disabled value="">{{
                                                                                    $t("value")
                                                                                }}
                                                                            </option>
                                                                            <option
                                                                                v-for="(value, i) in (
                                          category_options.find(
                                            e =>
                                              e.id ==
                                              add_data.options[m].option_id
                                          ) || {}
                                        ).values"
                                                                                :value="value.id"
                                                                                :key="i"
                                                                            >{{
                                                                                    value["name_" + $i18n.locale]
                                                                                }}
                                                                            </option
                                                                            >
                                                                        </select>
                                                                        <input
                                                                            type="number"
                                                                            min="0"
                                                                            class="form-control ltr"
                                                                            v-model="add_data.options[m].price"
                                                                            :class="{
                                        'is-invalid':
                                          add_errors['options.' + m + '.price']
                                      }"
                                                                            :placeholder="$t('price')"
                                                                        />

                                                                        <div class="input-group-append">
                                                                            <button
                                                                                class="btn btn-danger"
                                                                                @click="deleteOption(m)"
                                                                                type="button"
                                                                                v-tooltip="$t('delete_option')"
                                                                            >
                                                                                <i class="fa fa-times"></i>
                                                                            </button>
                                                                        </div>
                                                                        <span class="error invalid-feedback">{{
                                                                                (
                                                                                    add_errors[
                                                                                    "options." + m + ".option_value_id"
                                                                                        ] || []
                                                                                ).join("-")
                                                                            }}</span>
                                                                        <span class="error invalid-feedback">{{
                                                                                (
                                                                                    add_errors["options." + m + ".price"] ||
                                                                                    []
                                                                                ).join("-")
                                                                            }}</span>
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    v-if="
                                    add_data.options.length < options_max_count
                                  "
                                                                    class="btn btn-success btn-md"
                                                                    @click="addNewOption"
                                                                    type="button"
                                                                >
                                                                    {{ $t("add_new_option") }}
                                                                    <i class="fa fa-plus mr-1 ml-1"></i>
                                                                </button>
                                                            </div>
                                                            <br/>
                                                        </b-tab>
                                                        <b-tab
                                                            :title="$t('discount')"
                                                            :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  [
                                    'discount_type',
                                    'discount_value',
                                    'discount_start',
                                    'discount_end'
                                  ].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                                                        >
                                                            <div class="form-row col-md-12">
                                                                <div class="form-group col-md-12">
                                                                    <label>{{ $t("discount_type") }}</label>
                                                                    <select
                                                                        class="form-control custom-select"
                                                                        :class="{
                                      'is-invalid': add_errors.discount_type
                                    }"
                                                                        v-model="add_data.discount_type"
                                                                    >
                                                                        <option value="percentage">{{
                                                                                $t("percentage")
                                                                            }}
                                                                        </option>
                                                                        <option value="fixed">{{
                                                                                $t("fixed_value")
                                                                            }}
                                                                        </option>
                                                                    </select>
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.discount_type || []).join("-")
                                                                        }}</span>
                                                                </div>

                                                                <div class="form-group col-md-12">
                                                                    <label>{{ $t("discount_value") }}</label>
                                                                    <input
                                                                        type="number"
                                                                        min="0"
                                                                        :max="
                                      add_data.discount_type == 'percentage'
                                        ? 99
                                        : add_data.price
                                    "
                                                                        class="form-control ltr"
                                                                        :class="{
                                      'is-invalid': add_errors.discount_value
                                    }"
                                                                        v-model="add_data.discount_value"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.discount_value || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("discount_start") }}</label>
                                                                    <input
                                                                        type="date"
                                                                        class="form-control"
                                                                        :class="{
                                      'is-invalid': add_errors.discount_start
                                    }"
                                                                        v-model="add_data.discount_start"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.discount_start || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label>{{ $t("discount_end") }}</label>
                                                                    <input
                                                                        type="date"
                                                                        class="form-control"
                                                                        :class="{
                                      'is-invalid': add_errors.discount_end
                                    }"
                                                                        v-model="add_data.discount_end"
                                                                    />
                                                                    <span class="error invalid-feedback">{{
                                                                            (add_errors.discount_end || []).join("-")
                                                                        }}</span>
                                                                </div>
                                                            </div>
                                                        </b-tab>

                                                        <b-tab
                                                            :title="$t('images')"
                                                            :title-link-class="
                                (Object.keys(add_errors) || []).some(e =>
                                  ['images_input'].includes(e)
                                )
                                  ? 'invalid-tab'
                                  : ''
                              "
                                                        >
                                                            <div class="form-row col-md-12">
                                                                <div class="form-row">
                                                                    <div class="form-group new-input col-md-12">
                                                                        <label>{{ $t("images") }}</label>
                                                                        <b-form-file
                                                                            @change.prevent="upImages"
                                                                            multiple
                                                                            v-model="images_input"
                                                                            :file-name-formatter="formatNames"
                                                                            accept="image/*"
                                                                        ></b-form-file>
                                                                    </div>

                                                                    <div class="col-md-12">
                                                                        <div
                                                                            class="img_div"
                                                                            v-for="(img, i) in images_arr"
                                                                            :key="i"
                                                                        >
                                                                            <img :src="img.image"/>
                                                                            <div
                                                                                @click.prevent="removeImg(i)"
                                                                                v-if="!disabled"
                                                                            >
                                                                                <i class="fa fa-times"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-tab>
                                                    </b-tabs>
                                                </div>

                                                <button
                                                    type="submit"
                                                    class="btn btn-info mt-2"
                                                    :disabled="add_disabled"
                                                    @click="addProduct"
                                                >
                                                    {{ $t("add") }}
                                                    <b-spinner small v-if="add_disabled"></b-spinner>
                                                </button>
                                            </form>
                                        </b-card-body>
                                        <!-- /.card-body -->
                                    </b-card>
                                </b-card-text>
                            </b-tab
                            >
                        </b-tabs>

                        <!-- /.card -->
                    </div>
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    name: "Products",
    components: {
        Multiselect
    },
    data() {
        return {
            load: false,
            products: [],
            search_key: "",
            old_search_key: "",
            disabled_s: [],
            currentPage: 1,
            perPage: 1,
            rows: 1,
            add_disabled: false,
            add_success: "",
            add_error: "",
            add_errors: {},
            add_progress: 0,
            add_data_icon: null,
            add_data_image: null,
            add_data: {
                vendor_id: "",
                store_id: "",
                categories_ids: [],
                sub_categories_ids: [],
                discount_type: "percentage",
                options: [],
                is_special_order: 0,
                is_unlimited: 0
            },
            image_url: "",
            categories: [],
            sub_categories: [],
            brands: [],
            countries: [],
            offers: [],
            sizes: [],
            search_disabled: false,
            attribute_sets: [],
            attributes: [],
            images_input: [],
            images_arr: [],
            disabled: false,
            sort_key: "",
            sort_type: "asc",
            table_fields: [
                "#",
                "image",
                "name",
                "store",
                "vendor",
                "category",
                "sub_category",
                "price",
                "prepared_time",
                "status",
                "qty",
                "in_stock",
                "created_at",
                "actions"
            ],
            table_selected_fields: [],
            reject_prod_success: "",
            reject_prod_error: "",
            reject_prod_errors: {},
            reject_prod_disabled: false,
            reject_prod_data: {},
            accept_prod_success: "",
            accept_prod_error: "",
            accept_prod_errors: {},
            accept_prod_disabled: false,
            accept_prod_data: {},
            dimensions: [],
            stores: [],
            vendors: [],
            album_items: [],
            album_index: null,
            options: [],
            category_options: [],
            options_max_count: 0,
            product: {},
            updated_categories: []
        };
    },
    created() {
        this.table_selected_fields = this.$store.state.all_products_selected_fields;
        this.getProducts();
        this.getCategories();
        this.getOptions();
        this.getVendors();
        this.getBrands();
        this.getCountries();
        this.getOffers();
    },
    watch: {
        currentPage() {
            this.getProducts();
        },
        table_selected_fields() {
            this.$store.commit(
                "setAllProductsSelectedFields",
                this.table_selected_fields
            );
        },
        "add_data.vendor_id"() {
            if (this.add_data.vendor_id) {
                this.getVendorStores();
            } else {
                this.$set(this.add_data, "store_id", "");
            }
        },
        "add_data.categories_ids"() {
            this.sub_categories = [];
            if (this.add_data.categories_ids.length > 0) {
                this.add_data.categories_ids.forEach(e => {
                    // if (!this.sub_categories.find(m => m.id == e.id)) {
                    this.sub_categories.push({
                        id: e.id,
                        category: e["name_" + this.$i18n.locale],
                        sub_categories: e.sub_categories
                    });
                    //}
                });
                this.$set(
                    this.add_data,
                    "sub_categories_ids",
                    this.add_data.sub_categories_ids.filter(value => {
                        return this.add_data.categories_ids
                            .map(e => e.id)
                            .includes(value.category_id);
                    })
                );
            } else {
                this.sub_categories = [];
                this.$set(this.add_data, "sub_categories_ids", []);
            }

            this.category_options = this.options.filter(e => {
                let item =
                    e.category_id ==
                    this.add_data.categories_ids.find(c => c.id === e.category_id).id;
                return {
                    ...item
                };
            });
            this.updated_categories = this.options.filter(e => {
                let item =
                    e.category_id ==
                    this.add_data.categories_ids.find(c => c.id === e.category_id).id;
                return {
                    ...item
                };
            });
            this.options_max_count = this.category_options
                .map(e => e.values.length)
                .reduce(function (a, b) {
                    return a + b;
                }, 0);
            if (this.category_options.length > 0) {
                this.$set(this.add_data, "options", [
                    {
                        option_id: "",
                        option_value_id: "",
                        price: "",
                        image: null
                    }
                ]);
            } else {
                this.$set(this.add_data, "options", []);
            }
        },
        "add_data.name_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.add_data, "name_ar", "");
            }
        },
        "add_data.desc_ar"(newVal, oldVal) {
            if (!this.isArabicChars(newVal) && newVal !== oldVal) {
                this.$set(this.add_data, "desc_ar", "");
            }
        }
    },
    methods: {
        getProducts() {
            return this.axios
                .get("products", {
                    params: {
                        page: this.currentPage,
                        search_key: this.search_key,
                        sort_key: this.sort_key,
                        sort_type: this.sort_type
                    }
                })
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.products = res.result.products.data;
                        this.rows = res.result.products.total;
                        this.perPage = res.result.products.per_page;
                        this.image_url = res.result.image_url;
                    }
                })
                .catch(error => {
                    this.handleError("getProducts", error);
                })
                .finally(() => {
                    this.load = true;
                });
        },
        getVendors() {
            return this.axios.get("products/vendors").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.vendors = res.result.vendors;
                }
            });
        },
        getBrands() {
            return this.axios.get("brands?is_active=1").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.brands = res.result.brands;
                }
            });
        },
        getCountries() {
            return this.axios.get("countries?is_active=1").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.countries = res.result.countries;
                }
            });
        },
        getOffers() {
            return this.axios.get("offers?is_active=1").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    console.log(res.result.offers);
                    this.offers = res.result.offers;
                }
            });
        },
        getVendorStores() {
            return this.axios
                .get("products/vendorStores/" + this.add_data.vendor_id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.stores = res.result.stores;
                    }
                })
                .catch(error => {
                    this.handleError("getVendorStores", error);
                });
        },
        getCategories() {
            return this.axios.get("categories").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.categories = res.result.categories;
                }
            });
        },
        getOptions() {
            return this.axios.get("options").then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                    this.options = res.result.options;
                }
            });
        },
        searchAll() {
            if (this.search_key != this.old_search_key) {
                this.old_search_key = this.search_key;
                this.search_disabled = true;
                this.getProducts().then(() => {
                    this.search_disabled = false;
                });
            }
        },
        sortTable(key) {
            if (this.sort_key != key) {
                this.sort_type = "asc";
            } else {
                this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
            }
            this.sort_key = key;
            this.getProducts();
        },
        getDimensions() {
            return this.axios
                .get("dimensions")
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.dimensions = res.result.dimensions.data;
                    }
                })
                .catch(error => {
                    this.handleError("getDimensions", error);
                });
        },
        changeStatus(id) {
            let index = this.findIndex(this.products, "id", id);
            let i = this.findIndex(this.products, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("products/update_status/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.$noty.success(res.message);
                        this.products[index].status = res.result.status;
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("changeStatus", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("chnageStatus", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        showAcceptProduct(id) {
            let product = this.products.find(e => e.id == id);
            this.accept_prod_data = {
                id: id,
                weight: product.weight,
                dimension_id: product.dimension_id || ""
            };
            this.$refs.accept_modal.show();
        },
        acceptProduct(id) {
            let index = this.findIndex(this.products, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.accept_prod_disabled = true;

                        this.axios
                            .post("products/acceptProduct", {id: id})
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.products[index].status = "on";
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    if (typeof res.message === "object") {
                                        this.accept_prod_errors = res.message;
                                    } else {
                                        this.accept_prod_error = res.message;
                                    }
                                } else {
                                    this.handleError("acceptProduct", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("acceptProduct", error);
                            })
                            .finally(() => {
                                this.accept_prod_disabled = false;
                            });
                    }
                });
        },
        changeInStock(id) {
            let index = this.findIndex(this.products, "id", id);
            let i = this.findIndex(this.products, "id", id);
            this.$set(this.disabled_s, i, true);
            this.axios
                .post("products/update_in_stock/" + id)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.$noty.success(res.message);
                        this.products[index].in_stock = res.result.in_stock;
                    } else if (res.error_flag == 1) {
                        this.alert(res.message, this.$t("error"));
                    } else {
                        this.handleError("changeInStock", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("changeInStock", error);
                })
                .finally(() => {
                    this.$set(this.disabled_s, i, false);
                });
        },
        deleteProduct(id) {
            let index = this.findIndex(this.products, "id", id);

            this.$bvModal
                .msgBoxConfirm(this.$t("are_you_sure"), {
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    centered: true,
                    footerClass: "custom_modal_footer",
                    contentClass: "custom_modal_content"
                })
                .then(value => {
                    if (value) {
                        this.axios
                            .post("products/delete/" + id)
                            .then(response => {
                                let res = response.data;
                                if (res.error_flag == 0) {
                                    this.products.splice(index, 1);
                                    this.$noty.success(res.message);
                                } else if (res.error_flag == 1) {
                                    this.alert(res.message, this.$t("error"));
                                } else {
                                    this.handleError("deleteProduct", res.message);
                                }
                            })
                            .catch(error => {
                                this.handleError("deleteProduct", error);
                            });
                    }
                });
        },
        formatNames(files) {
            return files.length === 1
                ? files[0].name
                : `${files.length} files selected`;
        },
        upImages(evt) {
            evt.target.files.forEach(e => {
                if (this.isImage(e)) {
                    this.toBase64(e).then(res => {
                        this.images_arr.push({name: e.name, image: res});
                    });
                }
            });
        },
        removeImg(i) {
            this.images_input.splice(i, 1);
            this.images_arr.splice(i, 1);
        },
        addProduct() {
            this.add_disabled = true;
            let fd = new FormData();

            if (this.add_data_icon) {
                fd.append("image_input", this.add_data_icon, this.add_data_icon.name);
            }
            Object.keys(this.add_data).forEach(key => {
                if (this.add_data[key]) {
                    if (key == "options") {
                        this.add_data[key].forEach((e, i) => {
                            fd.append(
                                key + "[" + i + "][option_value_id]",
                                e.option_value_id
                            );
                            fd.append(key + "[" + i + "][price]", e.price);
                            if (e.image) {
                                fd.append(key + "[" + i + "][image]", e.image, e.image.name);
                            }
                        });
                    } else if (
                        ["sub_categories_ids", "price", "categories_ids"].includes(key)
                    ) {
                        if (
                            this.add_data.is_special_order === 1 &&
                            ["sub_categories_ids", "price"].includes(key)
                        ) {
                            fd.delete(key);
                        } else {
                            if (["sub_categories_ids", "categories_ids"].includes(key)) {
                                this.add_data[key].forEach((cat, idx) => {
                                    fd.append(`${key}[${idx}]`, cat.id);
                                });
                            } else {
                                fd.append(key, this.add_data[key]);
                            }
                        }
                    } else if (key === "qty") {
                        if (this.add_data.is_unlimited === 1) {
                            fd.delete(key, "");
                        } else {
                            fd.append(key, this.add_data[key]);
                        }
                    } else {
                        fd.append(key, this.add_data[key]);
                    }
                }
            });

            this.images_input.forEach((e, i) => {
                if (this.isImage(e)) {
                    fd.append("images_input[" + i + "]", e, e.name);
                }
            });

            this.add_error = this.add_success = "";
            this.axios
                .post("products/add", fd)
                .then(response => {
                    let res = response.data;
                    this.add_errors = {};
                    if (res.error_flag == 0) {
                        this.add_success = res.message;
                        this.getProducts();
                        this.add_data = {
                            vendor_id: "",
                            brand_id: "",
                            made_in_country_id: "",
                            offer_id: "",
                            store_id: "",
                            categories_ids: [],
                            sub_categories_ids: [],
                            discount_type: "percentage",
                            options: []
                        };
                        this.add_data_image = null;
                        this.add_data_icon = null;
                        this.images_input = [];
                        this.images_arr = [];
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth"
                        });
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.add_errors = res.message;
                        } else {
                            this.add_error = res.message;
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth"
                            });
                        }
                    } else {
                        this.handleError("addProduct", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("addProduct", error);
                })
                .finally(() => {
                    this.add_disabled = false;
                    this.add_progress = 0;
                });
        },
        showRejectProduct(id) {
            this.reject_prod_data = {};
            this.$set(this.reject_prod_data, "id", id);
            this.$refs.reject_modal.show();
        },
        rejectProduct() {
            let index = this.findIndex(this.products, "id", this.reject_prod_data.id);
            this.reject_prod_disabled = true;
            this.axios
                .post("products/rejectProduct", this.reject_prod_data)
                .then(response => {
                    let res = response.data;
                    if (res.error_flag == 0) {
                        this.products[index].status = "rejected";
                        this.products[index].rejection_reason = res.result.rejection_reason;
                        this.$noty.success(res.message);
                        this.$refs.reject_modal.hide();
                    } else if (res.error_flag == 1) {
                        if (typeof res.message === "object") {
                            this.reject_prod_errors = res.message;
                        } else {
                            this.reject_prod_error = res.message;
                        }
                    } else {
                        this.handleError("rejectProduct", res.message);
                    }
                })
                .catch(error => {
                    this.handleError("rejectProduct", error);
                })
                .finally(() => {
                    this.reject_prod_disabled = false;
                });
        },
        showProductAlbum(id) {
            let product = this.products.find(e => e.id == id);
            if (product.image) {
                this.album_items = [
                    this.image_url + "/" + product.image,
                    ...product.images.map(e => this.image_url + "/" + e.image)
                ];
                this.album_index = 0;
            }
        },
        addNewOption() {
            let options = this.add_data.options;
            if (options[options.length - 1].option_id) {
                this.add_data.options.push({
                    option_id: "",
                    option_value_id: "",
                    price: "",
                    image: null
                });
            }
        },
        deleteOption(index) {
            this.add_data.options.splice(index, 1);
        },
        viewProduct(id) {
            let item = this.products.find(e => e.id === id);
            this.product = item ?? {};
            this.$refs.view_product_modal.show();
        },
        filter_category(items) {
            // let cloneItems = [];
            // let options = this.add_data.options;
            // for (let i = 0; i <= idx; i++) {
            //   options.forEach(opt => {
            //     if (opt.option_id && opt.option_id !== options[i].option_id) {
            //       // cloneItems = items.filter(e => e.id !== opt.option_id);
            //       items.forEach(it => {
            //         if (it.id !== opt.option_id) {
            //           cloneItems.push(;
            //         }
            //       });
            //     }
            //   });
            // }
            return items;
        }
    }
};
</script>

<style scoped>
.img_div {
    margin: 15px;
    display: inline-block;
    position: relative;
}

.img_div img {
    height: 120px;
    width: 120px;
}

.img_div div {
    background: #ff0018;
    position: absolute;
    top: -10px;
    color: white;
    left: -10px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}
</style>
