<template>
  <div>
    <b-modal
      ref="customize_table_modal"
      centered
      :title="$t('customize_table')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-row" style="padding: 0 20px">
              <div
                class="form-group col-md-6"
                v-for="(field, i) in table_fields"
                :key="i"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  aria-label="Checkbox for following text input"
                  :value="field"
                  :id="'f_' + field"
                  v-model="table_selected_fields"
                  style="vertical-align: middle;"
                /><label
                  class="form-check-label"
                  :for="'f_' + field"
                  style="color: blue;"
                >
                  {{ $t(field.toLowerCase()) }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      ref="reject_modal"
      centered
      :title="$t('reject')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="col-md-12">
          <b-alert
            :show="reject_vendor_error || reject_vendor_success ? true : false"
            :variant="reject_vendor_error ? 'danger' : 'success'"
            dismissible
            @dismissed="reject_vendor_error = reject_vendor_success = ''"
          >
            {{ reject_vendor_error || reject_vendor_success }}
          </b-alert>
          <div class="form-row ">
            <div class="form-group col-md-12">
              <label>{{ $t("rejection_reason") }}</label>
              <textarea
                type="text"
                class="form-control"
                :class="{ 'is-invalid': reject_vendor_errors.rejection_reason }"
                v-model="reject_vendor_data.rejection_reason"
              ></textarea>
              <span class="error invalid-feedback">{{
                (reject_vendor_errors.rejection_reason || []).join("-")
              }}</span>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="reject_vendor_disabled"
            @click.prevent="rejectVendor"
          >
            {{ $t("save") }}
            <b-spinner small v-if="reject_vendor_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <b-modal
      ref="vendor_status_modal"
      centered
      :title="$t('change_status')"
      header-class="new_modal_header"
      hide-footer
    >
      <div class="d-block">
        <form class="form">
          <b-alert
            :show="edit_error || edit_success ? true : false"
            :variant="edit_error ? 'danger' : 'success'"
            dismissible
            @dismissed="edit_error = edit_success = ''"
          >
            {{ edit_error || edit_success }}
          </b-alert>
          <div class="form-group">
            <label class="mb-1">{{ $t("status") }}</label>
            <select
              class="form-control custom-select"
              v-model="edit_data.status"
              :class="{
                'is-invalid': edit_errors.status
              }"
            >
              <option value="" selected disabled>{{ $t("select") }}</option>
              <option v-for="(s, index) in status" :key="index" :value="s">
                {{ $t(s) }}
              </option>
            </select>
            <span class="error invalid-feedback">{{
              (edit_errors.status || []).join("-")
            }}</span>
          </div>
          <button
            type="submit"
            class="btn btn-info mt-2"
            :disabled="edit_disabled"
            @click.prevent="updateVendorStatus"
          >
            {{ $t("save") }}
            <b-spinner small v-if="edit_disabled"></b-spinner>
          </button>
        </form>
      </div>
    </b-modal>

    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <b-tabs
              pills
              card
              no-key-nav
              content-class="custom-card"
              nav-wrapper-class="custom-nav-card"
            >
              <b-tab no-body :title="$t('all')" active>
                <template v-slot:title>
                  <i class="fa fa-list ml-2 mr-2"></i> {{ $t("all") }}
                </template>
                <b-card-text>
                  <b-card no-body class="card">
                    <b-card-header>
                      <div class="card-tools">
                        <div class="input-group input-group-sm">
                          <i
                            class="fas fa-cog c_pointer customize_table"
                            :title="$t('customize_table')"
                            @click="customizeTable"
                          ></i>
                          <input
                            type="text"
                            class="form-control float-right"
                            :placeholder="$t('search')"
                            v-model="search_key"
                          />
                          <div class="input-group-append">
                            <button
                              type="submit"
                              :disabled="search_disabled"
                              @click.prevent="searchAll"
                              class="btn btn-default"
                            >
                              <i
                                class="fa fa-spinner fa-spin"
                                v-if="search_disabled"
                              ></i>
                              <i class="fas fa-search" v-else></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-header>
                    <!-- /.card-header -->
                    <b-card-body class="table-responsive p-0">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th v-if="table_selected_fields.includes('#')">
                              #
                            </th>
                            <th
                              v-if="table_selected_fields.includes('name')"
                              class="sort"
                              @click="sortTable('name')"
                            >
                              {{ $t("name") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('user')"
                              class="sort"
                              @click="sortTable('user_id')"
                            >
                              {{ $t("user") }}
                            </th>

                            <th
                              v-if="
                                table_selected_fields.includes('device_type')
                              "
                              class="sort"
                              @click="sortTable('device_type')"
                            >
                              {{ $t("device_type") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('live_orders')
                              "
                              class="sort"
                              @click="sortTable('live_orders')"
                            >
                              {{ $t("live_orders") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes(
                                  'completed_orders'
                                )
                              "
                              class="sort"
                              @click="sortTable('completed_orders')"
                            >
                              {{ $t("completed_orders") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes(
                                  'cancelled_orders'
                                )
                              "
                              class="sort"
                              @click="sortTable('cancelled_orders')"
                            >
                              {{ $t("cancelled_orders") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('wallet')"
                              class="sort"
                              @click="sortTable('wallet')"
                            >
                              {{ $t("wallet") }}
                            </th>
                            <th
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              class="sort"
                              @click="sortTable('created_at')"
                            >
                              {{ $t("created_at") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('status')"
                              class="sort"
                              @click="sortTable('status')"
                            >
                              {{ $t("status") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('products')"
                            >
                              {{ $t("products") }}
                            </th>
                            <th
                              v-if="table_selected_fields.includes('actions')"
                            >
                              {{ $t("actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              :colspan="table_selected_fields.length"
                              v-if="!load"
                            >
                              <i class="fa fa-sync  fa-spin load-con"></i>
                            </td>
                            <td
                              :colspan="table_selected_fields.length"
                              class="no_data"
                              v-if="load && vendors.length == 0"
                            >
                              {{ $t("no_data") }}
                            </td>
                          </tr>
                          <tr
                            v-for="(item, i) in vendors"
                            :key="i"
                            v-show="load"
                          >
                            <td v-if="table_selected_fields.includes('#')">
                              {{ i + 1 }}
                            </td>
                            <td v-if="table_selected_fields.includes('name')">
                              {{ item.name }}
                              <br />
                              {{ item.contact_mobile }}
                            </td>
                            <td v-if="table_selected_fields.includes('user')">
                              {{ (item.user || {}).name }}
                              <br v-if="(item.user || {}).name" />
                              {{ (item.user || {}).mobile || "-" }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('device_type')
                              "
                            >
                              {{ item.device_type }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('live_orders')
                              "
                            >
                              {{ item.live_orders }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'completed_orders'
                                )
                              "
                            >
                              {{ item.completed_orders }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes(
                                  'cancelled_orders'
                                )
                              "
                            >
                              {{ item.cancelled_orders }}
                            </td>
                            <td v-if="table_selected_fields.includes('wallet')">
                              {{ item.wallet }}
                            </td>
                            <td
                              v-if="
                                table_selected_fields.includes('created_at')
                              "
                              v-html="dateTimeFormat(item.created_at)"
                            ></td>
                            <td v-if="table_selected_fields.includes('status')">
                              <span
                                :class="
                                  'badge badge-' +
                                    (item.status == 'on' ? 'info' : 'danger')
                                "
                                >{{ $t(item.status) }}</span
                              >
                            </td>
                            <td
                              v-if="table_selected_fields.includes('products')"
                            >
                              <router-link
                                v-if="$can('edit vendors')"
                                :to="'/vendors/' + item.id + '/products'"
                                class="btn btn-success btn-sm"
                                v-tooltip="$t('view')"
                              >
                                <i class="fas fa-eye"> </i>
                              </router-link>
                            </td>
                            <td
                              class="actions-group"
                              v-if="table_selected_fields.includes('actions')"
                            >
                              <button
                                v-if="
                                  (item.status == 'contract' ||
                                    item.status == 'on') &&
                                    false
                                "
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('download_contract')"
                                @click="downloadContract(item.id)"
                              >
                                <i class="fas fa-file-contract"></i>
                              </button>

                              <button
                                v-if="
                                  $can('edit vendors') &&
                                    item.status == 'pending'
                                "
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('accept')"
                                @click="acceptVendor(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-check" v-else></i>
                              </button>
                              <button
                                v-if="
                                  $can('edit vendors') &&
                                    item.status == 'pending'
                                "
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('reject')"
                                @click="showRejectVendor(item.id)"
                              >
                                <i class="fas fa-exclamation-triangle"></i>
                              </button>

                              <button
                                v-if="
                                  $can('edit vendors') &&
                                    item.status != 'pending'
                                "
                                class="btn btn-primary btn-sm"
                                v-tooltip="$t('change_status')"
                                @click="showChangeStatus(item.id)"
                              >
                                <b-spinner small v-if="disabled_s[i]">
                                </b-spinner>
                                <i class="fas fa-exchange-alt" v-else></i>
                              </button>
                              <router-link
                                v-if="$can('edit vendors')"
                                :to="'/vendors/' + item.id"
                                class="btn btn-info btn-sm"
                                v-tooltip="$t('view')"
                              >
                                <i class="fas fa-eye"> </i>
                              </router-link>
                              <button
                                v-if="$can('delete vendors')"
                                @click="deleteVendor(item.id)"
                                class="btn btn-danger btn-sm"
                                v-tooltip="$t('delete')"
                              >
                                <i class="fas fa-trash"> </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-card-body>
                    <!-- /.card-body -->
                    <b-card-footer class="card-footer clearfix">
                      <b-pagination
                        class="m-2"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        :prev-text="$t('previous')"
                        :next-text="$t('next')"
                        align="right"
                        :first-number="true"
                        :last-number="true"
                      ></b-pagination>
                    </b-card-footer>
                  </b-card> </b-card-text
              ></b-tab>
              <b-tab no-body :title="$t('add')" v-if="$can('add vendors')">
                <template v-slot:title>
                  <i class="fa fa-plus ml-2 mr-2"></i> {{ $t("add") }}
                </template>
                <b-card-text>
                  <b-card no-body>
                    <b-card-body>
                      <form class="col-md-8">
                        <b-alert
                          :show="add_error || add_success ? true : false"
                          :variant="add_error ? 'danger' : 'success'"
                          dismissible
                          @dismissed="add_error = add_success = ''"
                        >
                          {{ add_error || add_success }}
                        </b-alert>
                        <b-tabs>
                          <b-tab
                            no-body
                            :title="$t('info')"
                            :title-link-class="
                              (Object.keys(add_errors) || []).some(e =>
                                [
                                  'name',
                                  'email',
                                  'mobile',
                                  'contact_mobile'
                                ].includes(e)
                              )
                                ? 'invalid-tab'
                                : ''
                            "
                          >
                            <div class="form-row mt-3">
                              <div class="form-group col-md-12">
                                <label>{{ $t("name") }}</label>
                                <input
                                  type="text"
                                  class="form-control rtl"
                                  :class="{
                                    'is-invalid': add_errors.name
                                  }"
                                  v-model="add_data.name"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.name || []).join("-")
                                }}</span>
                              </div>

                              <div class="form-group col-md-12">
                                <label>{{ $t("email") }}</label>
                                <input
                                  type="text"
                                  class="form-control ltr"
                                  v-model="add_data.email"
                                  :class="{
                                    'is-invalid': add_errors.email
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.email || []).join("-")
                                }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <label>{{ $t("mobile") }}</label>
                                <input
                                  type="number"
                                  min="0"
                                  class="form-control ltr"
                                  v-model="add_data.mobile"
                                  :class="{
                                    'is-invalid': add_errors.mobile
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.mobile || []).join("-")
                                }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <label>{{ $t("contact_mobile") }}</label>
                                <input
                                  type="number"
                                  min="0"
                                  class="form-control ltr"
                                  v-model="add_data.contact_mobile"
                                  :class="{
                                    'is-invalid': add_errors.contact_mobile
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.contact_mobile || []).join("-")
                                }}</span>
                              </div>
                              <div class="form-group col-md-12">
                                <label>{{ $t("commission_per") }} (%)</label>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  class="form-control ltr"
                                  v-model="add_data.commission_per"
                                  :class="{
                                    'is-invalid': add_errors.commission_per
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.commission_per || []).join("-")
                                }}</span>
                              </div>
                              <div class="form-group col-md-12">
                                <label>{{ $t("national_id") }}</label>
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  class="form-control ltr"
                                  v-model="add_data.national_id"
                                  :class="{
                                    'is-invalid': add_errors.national_id
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.national_id || []).join("-")
                                }}</span>
                              </div>
                              <div class="form-group col-md-12">
                                <label>{{ $t("national_id_image") }}</label>

                                <div class="image_div" v-if="national_id_image">
                                  <img :src="national_id_image" />
                                  <i
                                    class="fa fa-times"
                                    @click.prevent="
                                      national_id_image = null;
                                      national_id_icon = null;
                                    "
                                  ></i>
                                </div>
                                <b-form-file
                                  v-else
                                  :placeholder="$t('choose_file')"
                                  accept="image/*"
                                  @change.prevent="
                                    onImageChange($event, 'national_id')
                                  "
                                  ref="national_id_icon"
                                ></b-form-file>
                                <span
                                  class="error invalid-feedback"
                                  style="display:block"
                                  v-if="add_errors.national_id_image_input"
                                  >{{
                                    (
                                      add_errors.national_id_image_input || []
                                    ).join("-")
                                  }}</span
                                >
                              </div>
                            </div>
                          </b-tab>
                          <b-tab
                            no-body
                            :title="$t('store')"
                            :title-link-class="
                              (Object.keys(add_errors) || []).some(e =>
                                [
                                  'store_logo_input',
                                  'store_name',
                                  'store_contact_mobile',
                                  'store_url',
                                  'address',
                                  'lat',
                                  'lng',
                                  'contact_mobile'
                                ].includes(e)
                              )
                                ? 'invalid-tab'
                                : ''
                            "
                            v-if="add_data.add_store"
                          >
                            <div class="form-row mt-3">
                              <div class="form-group col-md-12">
                                <label>{{ $t("image") }}</label>

                                <div class="image_div" v-if="add_data_image">
                                  <img :src="add_data_image" />
                                  <i
                                    class="fa fa-times"
                                    @click.prevent="
                                      add_data_image = null;
                                      add_data_icon = null;
                                    "
                                  ></i>
                                </div>
                                <b-form-file
                                  v-else
                                  :placeholder="$t('choose_file')"
                                  accept="image/*"
                                  @change.prevent="
                                    onImageChange($event, 'add_data')
                                  "
                                  ref="add_data_icon"
                                ></b-form-file>
                                <span
                                  class="error invalid-feedback"
                                  style="display:block"
                                  v-if="add_errors.store_logo_input"
                                  >{{
                                    (add_errors.store_logo_input || []).join(
                                      "-"
                                    )
                                  }}</span
                                >
                              </div>

                              <div class="form-group col-md-12">
                                <label>{{ $t("store_name") }}</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  :class="{
                                    'is-invalid': add_errors.store_name
                                  }"
                                  v-model="add_data.store_name"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.store_name || []).join("-")
                                }}</span>
                              </div>

                              <div class="form-group col-md-6">
                                <label>{{ $t("contact_mobile") }}</label>
                                <input
                                  type="number"
                                  min="0"
                                  class="form-control ltr"
                                  v-model="add_data.store_contact_mobile"
                                  :class="{
                                    'is-invalid':
                                      add_errors.store_contact_mobile
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.store_contact_mobile || []).join(
                                    "-"
                                  )
                                }}</span>
                              </div>
                              <div class="form-group col-md-6">
                                <label>{{ $t("store_url") }}</label>
                                <input
                                  type="text"
                                  class="form-control ltr"
                                  v-model="add_data.store_url"
                                  :class="{
                                    'is-invalid': add_errors.store_url
                                  }"
                                />
                                <span class="error invalid-feedback">{{
                                  (add_errors.store_url || []).join("-")
                                }}</span>
                              </div>

                              <div
                                class="input-group"
                                style="height:250px;border-radius:0.25rem;overflow:hidden;"
                              >
                                <div class="col-md-12" style="padding:0;">
                                  <GmapMap
                                    :center="{
                                      lat: add_data.lat,
                                      lng: add_data.lng
                                    }"
                                    :zoom="8"
                                    map-type-id="terrain"
                                    :options="{
                                      mapTypeControl: false,
                                      streetViewControl: false
                                    }"
                                    style=" height: 250px"
                                  >
                                    <GmapMarker
                                      :position="{
                                        lat: add_data.lat,
                                        lng: add_data.lng
                                      }"
                                      :draggable="true"
                                      @dragend="updatePosition"
                                    />
                                  </GmapMap>
                                </div>
                                <div
                                  class="get_current_location"
                                  v-tooltip="$t('my_location')"
                                  @click="getLocation"
                                >
                                  <i class="fas fa-location-arrow"></i>
                                </div>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>

                        <button
                          type="submit"
                          class="btn btn-info mt-2"
                          :disabled="add_disabled"
                          @click="addVendor"
                        >
                          {{ $t("add") }}
                          <b-spinner small v-if="add_disabled"></b-spinner>
                        </button>
                      </form>
                    </b-card-body>
                    <!-- /.card-body -->
                  </b-card>
                </b-card-text></b-tab
              >
            </b-tabs>

            <!-- /.card -->
          </div>
        </div>
        <!-- /.row -->
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->
  </div>
</template>

<script>
export default {
  name: "Vendors",
  data() {
    return {
      load: false,
      vendors: [],
      search_key: "",
      old_search_key: "",
      disabled_s: [],
      currentPage: 1,
      perPage: 1,
      rows: 1,
      add_disabled: false,
      add_success: "",
      add_error: "",
      add_errors: {},
      add_progress: 0,
      add_data_icon: null,
      add_data_image: null,
      national_id_icon: null,
      national_id_image: null,
      add_data: {
        add_store: true,

        lat: 24.774265,
        lng: 46.738586
      },
      image_url: "",

      search_disabled: false,
      disabled: false,
      sort_key: "",
      sort_type: "asc",
      table_fields: [
        "#",
        "name",
        "user",
        "live_orders",
        "completed_orders",
        "cancelled_orders",
        "wallet",
        "device_type",
        "created_at",
        "status",
        "products",
        "actions"
      ],
      table_selected_fields: [],
      reject_vendor_disabled: false,
      reject_vendor_success: "",
      reject_vendor_error: "",
      reject_vendor_errors: {},
      reject_vendor_data: {},
      edit_success: "",
      edit_error: "",
      edit_errors: {},
      edit_disabled: false,
      edit_data: {},
      status: ["on", "off", "rejected", "pending"]
    };
  },
  created() {
    this.table_selected_fields = this.$store.state.vendors_selected_fields;
    this.getVendors();
  },
  watch: {
    currentPage() {
      this.getVendors();
    },
    table_selected_fields() {
      this.$store.commit(
        "setVendorsSelectedFields",
        this.table_selected_fields
      );
    }
  },
  methods: {
    getVendors() {
      return this.axios
        .get("vendors", {
          params: {
            page: this.currentPage,
            search_key: this.search_key,
            sort_key: this.sort_key,
            sort_type: this.sort_type
          }
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.vendors = res.result.vendors.data;
            this.rows = res.result.vendors.pagination.total;
            this.perPage = res.result.vendors.pagination.per_page;
            this.image_url = res.result.image_url;
          }
        })
        .catch(error => {
          this.handleError("getVendors", error);
        })
        .finally(() => {
          this.load = true;
        });
    },

    sortTable(key) {
      if (this.sort_key != key) {
        this.sort_type = "asc";
      } else {
        this.sort_type = this.sort_type == "asc" ? "desc" : "asc";
      }
      this.sort_key = key;
      this.getVendors();
    },
    searchAll() {
      if (this.search_key != this.old_search_key) {
        this.old_search_key = this.search_key;
        this.search_disabled = true;
        this.getVendors().then(() => {
          this.search_disabled = false;
        });
      }
    },

    addVendor() {
      this.add_disabled = true;
      let fd = new FormData();

      if (this.add_data_icon && this.add_data.add_store) {
        fd.append(
          "store_logo_input",
          this.add_data_icon,
          this.add_data_icon.name
        );
      }

      if (this.national_id_icon) {
        fd.append(
          "national_id_image_input",
          this.national_id_icon,
          this.national_id_icon.name
        );
      }
      Object.keys(this.add_data).forEach(key => {
        if (this.add_data[key]) {
          fd.append(key, this.add_data[key]);
        }
      });
      this.axios
        .post("vendors/add", fd)
        .then(response => {
          let res = response.data;
          this.add_error = this.add_success = "";
          if (res.error_flag == 0) {
            this.add_success = res.message;
            this.getVendors();
            this.add_data = {
              add_store: true,

              lat: 24.774265,
              lng: 46.738586
            };
            this.add_data_image = null;
            this.add_data_icon = null;

            this.national_id_icon = null;
            this.national_id_image = null;
            this.add_errors = {};
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.add_errors = res.message;
            } else {
              this.add_error = res.message;
              this.add_errors = {};
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
              });
            }
          } else {
            this.handleError("addVendor", res.message);
          }
        })
        .catch(error => {
          this.handleError("addVendor", error);
        })
        .finally(() => {
          this.add_disabled = false;
          this.add_progress = 0;
        });
    },
    updateVendorStatus() {
      this.edit_disabled = true;
      let id = this.vendor_id;
      let index = this.findIndex(this.vendors, "id", id);
      this.axios
        .post("vendors/update_status", {
          id: id,
          status: this.edit_data.status,
          reason: this.edit_data.reason
        })
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.vendors[index].status = res.result.status;
            this.$noty.success(res.message);
            this.$refs.vendor_reject_modal.hide();
          }
        })
        .catch(err => {
          let error = err.response.data;
          if (error.error_flag == 1) {
            if (typeof error.message === "object") {
              this.edit_errors = error.message;
            } else {
              this.edit_error = error.message;
              this.edit_errors = {};
            }
          } else {
            this.handleError("changeStatus", error.message);
          }
        })
        .finally(() => {
          this.edit_disabled = false;
        });
    },
    showChangeStatus(id) {
      this.vendor_id = id;
      this.$set(
        this.edit_data,
        "status",
        this.vendors.find(item => item.id == id).status
      );
      this.$refs.vendor_status_modal.show();
    },
    acceptVendor(id) {
      let index = this.findIndex(this.vendors, "id", id);
      let i = this.findIndex(this.vendors, "id", id);
      this.$set(this.disabled_s, i, true);
      this.axios
        .post("vendors/acceptVendor/" + id)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.vendors[index].status = "on";
            this.$noty.success(res.message);
          } else if (res.error_flag == 1) {
            this.alert(res.message, this.$t("error"));
          } else {
            this.handleError("acceptVendor", res.message);
          }
        })
        .catch(error => {
          this.handleError("acceptVendor", error);
        })
        .finally(() => {
          this.$set(this.disabled_s, i, false);
        });
    },

    showRejectVendor(id) {
      this.reject_vendor_data = {};
      this.$set(this.reject_vendor_data, "id", id);
      this.$refs.reject_modal.show();
    },
    rejectVendor() {
      let index = this.findIndex(
        this.vendors,
        "id",
        this.reject_vendor_data.id
      );
      this.reject_prod_disabled = true;
      this.axios
        .post("vendors/rejectVendor", this.reject_vendor_data)
        .then(response => {
          let res = response.data;
          if (res.error_flag == 0) {
            this.vendors[index].status = "rejected";
            this.vendors[index].rejection_reason = res.result.rejection_reason;
            this.$noty.success(res.message);
            this.$refs.reject_modal.hide();
          } else if (res.error_flag == 1) {
            if (typeof res.message === "object") {
              this.reject_prod_errors = res.message;
            } else {
              this.reject_prod_error = res.message;
            }
          } else {
            this.handleError("rejectVendor", res.message);
          }
        })
        .catch(error => {
          this.handleError("rejectVendor", error);
        })
        .finally(() => {
          this.reject_prod_disabled = false;
        });
    },

    deleteVendor(id) {
      let index = this.findIndex(this.vendors, "id", id);

      this.$bvModal
        .msgBoxConfirm(this.$t("are_you_sure"), {
          okVariant: "danger",
          okTitle: this.$t("yes"),
          cancelTitle: this.$t("no"),
          centered: true,
          footerClass: "custom_modal_footer",
          contentClass: "custom_modal_content"
        })
        .then(value => {
          if (value) {
            this.axios
              .post("vendors/delete/" + id)
              .then(response => {
                let res = response.data;
                if (res.error_flag == 0) {
                  this.vendors.splice(index, 1);
                  this.$noty.success(res.message);
                } else if (res.error_flag == 1) {
                  this.alert(res.message, this.$t("error"));
                } else {
                  this.handleError("deleteVendor", res.message);
                }
              })
              .catch(error => {
                this.handleError("deleteVendor", error);
              });
          }
        });
    },
    updatePosition(event, flag = null) {
      let pos = {};
      if (flag) {
        pos = event;
      } else {
        pos = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      }

      this.$set(this.add_data, "lat", pos.lat);
      this.$set(this.add_data, "lng", pos.lng);
      this.$geocoder.send(pos, response => {
        if (response.status == "OK") {
          let address = response.results[0].formatted_address;
          this.$set(this.add_data, "address", address);
          this.$refs.autocomplete.$refs.input.value = address;
        }
      });
    },
    setPlace(place) {
      if (place.geometry) {
        this.$set(this.add_data, "lat", place.geometry.location.lat());
        this.$set(this.add_data, "lng", place.geometry.location.lng());
        this.$set(
          this.add_data,
          "address",
          this.$refs.autocomplete.$refs.input.value
        );
      }
    },
    customizeTable() {
      this.$refs.customize_table_modal.show();
    },
    getLocation() {
      if (navigator.geolocation)
        navigator.geolocation.getCurrentPosition(
          this.setLocation,
          this.locationError
        );
      else alert("Geolocation is not supported by this browser.");
    },
    setLocation(pos) {
      let map_pos = {
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      };
      this.updatePosition(map_pos, 1);
    },
    locationError() {
      /*if (error.PERMISSION_DENIED)
        alert("User denied the request for Geolocation.");
      else
       if (error.POSITION_UNAVAILABLE)
        alert("Location information is unavailable.");
      else if (error.TIMEOUT)
        alert("The request to get user location timed out.");
      else alert("An unknown error occurred.");*/
    },
    downloadContract(id) {
      this.axios
        .get("vendors/contract/" + id, {
          responseType: "blob"
        })
        .then(response => {
          let blob = new Blob([response.data], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "contract_" + id + ".pdf";
          link.click();
        })
        .catch(error => {
          this.handleError("downloadContract", error);
        });
    }
  }
};
</script>

<style scoped>
.img_div {
  margin: 15px;
  display: inline-block;
  position: relative;
}

.img_div img {
  height: 120px;
  width: 120px;
}

.img_div div {
  background: #ff0018;
  position: absolute;
  top: -10px;
  color: white;
  left: -10px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}
</style>
