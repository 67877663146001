import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home";
import Products from "../views/Products";
import Users from "../views/Users";
import Associations from "../views/Associations";
import User from "../views/User";
import Product from "../views/Product";
import Options from "../views/Options";
import Help from "../views/Help";
import Attributes from "../views/Attributes";
import AttributeSets from "../views/AttributeSets";
import WelcomePages from "../views/WelcomePages";
import Contacts from "../views/Contacts";
import Discounts from "../views/Discounts";
import Coupons from "../views/Coupons";
import Regions from "../views/Regions";
import Notifications from "../views/Notifications";
import AppNotifications from "../views/AppNotifications";
import Cities from "../views/Cities";
import Categories from "../views/Categories";
import SubCategories from "../views/SubCategories";
import CReasons from "../views/CReasons";
import Sections from "../views/Sections";
import Posts from "../views/Posts";
import Profile from "../views/Profile";
import Slider from "../views/settings/Slider";
import Banners from "../views/settings/Banners";
import Images from "../views/settings/Images";
import Info from "../views/settings/Info";
import Pages from "../views/settings/Pages";
import Settings from "../views/Settings";
import AboutUs from "../views/AboutUs";
import EmailTemplates from "../views/EmailTemplates";
import NotificationTemplates from "../views/NotificationTemplates";
import PaymentGates from "../views/settings/PaymentGates";
import Admins from "../views/Admins";
import Groups from "../views/Groups";
import Orders from "../views/Orders";
import Login from "../views/Login.vue";
import Error from "../views/Error.vue";
import Vendors from "../views/Vendors.vue";
import Stores from "../views/Stores.vue";
import Vendor from "../views/Vendor.vue";
import VendorProducts from "../views/VendorProducts.vue";
import Ads from "../views/Ads.vue";
import Incomes from "../views/Incomes.vue";
import DeliveryCompanies from "../views/DeliveryCompanies.vue";
import DeliveryCompany from "../views/DeliveryCompany.vue";
import Packs from "../views/Packs.vue";
import PGroups from "../views/PGroups.vue";
import Collections from "../views/Collections.vue";
import WRequests from "../views/WRequests.vue";
import Invoices from "../views/Invoices.vue";
import Wallet from "../views/Wallet.vue";

import auth from "../middleware/auth.js";
import can from "../middleware/can.js";
import is_admin from "../middleware/admin.js";
import is_vendor from "../middleware/vendor.js";
import Brands from "../views/Brands";
import Countries from "../views/Countries";
import Offers from "../views/Offers";
import ImportantStores from "../views/ImportantStores";
import HomeSection from "../views/HomeSection";
//import is_merchant from "../middleware/merchant.js";
import VendorLogin from "../views/Vendor/Login.vue";
import VendorHome from "../views/Vendor/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {title: "login"}
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            middleware: [auth, is_admin, can],
            title: "dashboard",
            permission: "home"
        }
    },
    {
        path: "/live_orders",
        name: "LiveOrders",
        component: Orders,
        meta: {
            middleware: [auth, is_admin, can],
            title: "live_orders",
            permission: "orders"
        }
    },
    {
        path: "/cancelled_orders",
        name: "CancelledOrders",
        component: Orders,
        meta: {
            middleware: [auth, is_admin, can],
            title: "cancelled_orders",
            permission: "orders"
        }
    },
    {
        path: "/past_orders",
        name: "PastOrders",
        component: Orders,
        meta: {
            middleware: [auth, is_admin, can],
            title: "past_orders",
            permission: "orders"
        }
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
        meta: {
            middleware: [auth, is_admin, can],
            title: "products",
            permission: "products"
        }
    },
    {
        path: "/product/:id",
        name: "Product",
        component: Product,
        meta: {
            middleware: [auth, is_admin, can],
            title: "product_2",
            permission: "products"
        }
    },
    {
        path: "/associations",
        name: "Associations",
        component: Associations,
        meta: {
            middleware: [auth, is_admin, can],
            title: "associations",
            permission: "associations"
        }
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            middleware: [auth, is_admin, can],
            title: "users",
            permission: "users"
        }
    },
    {
        path: "/users/:id",
        name: "User",
        component: User,
        meta: {
            middleware: [auth, is_admin, can],
            title: "user_2",
            permission: "users"
        }
    },
    {
        path: "/delivery_companies/:id",
        name: "DeliveryCompany",
        component: DeliveryCompany,
        meta: {
            middleware: [auth, is_admin, can],
            title: "delivery_company_2",
            permission: "delivery_companies"
        }
    },
    {
        path: "/pages",
        name: "Pages",
        component: Pages,
        meta: {
            middleware: [auth, is_admin, can],
            title: "pages",
            permission: "pages"
        }
    },
    {
        path: "/welcome_pages",
        name: "WelcomePages",
        component: WelcomePages,
        meta: {
            middleware: [auth, is_admin, can],
            title: "welcome_pages",
            permission: "welcome_pages"
        }
    },
    {
        path: "/vendors",
        name: "Vendors",
        component: Vendors,
        meta: {
            middleware: [auth, is_admin, can],
            title: "vendors",
            permission: "vendors"
        }
    },
    {
        path: "/vendors/:id",
        name: "vendor",
        component: Vendor,
        meta: {
            middleware: [auth, is_admin, can],
            title: "vendor",
            permission: "vendors"
        }
    },
    {
        path: "/vendors/:id/products",
        name: "VendorProducts",
        component: VendorProducts,
        meta: {
            middleware: [auth, is_admin, can],
            title: "vendor_products",
            permission: "products"
        }
    },
    {
        path: "/stores",
        name: "Stores",
        component: Stores,
        meta: {
            middleware: [auth, is_admin, can],
            title: "stores",
            permission: "stores"
        }
    },
    {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        meta: {
            middleware: [auth, is_admin, can],
            title: "contacts",
            permission: "contacts"
        }
    },
    {
        path: "/discounts",
        name: "Discounts",
        component: Discounts,
        meta: {
            middleware: [auth, is_admin, can],
            title: "discounts",
            permission: "discounts"
        }
    },
    {
        path: "/coupons",
        name: "Coupons",
        component: Coupons,
        meta: {
            middleware: [auth, is_admin, can],
            title: "coupons",
            permission: "coupons"
        }
    },
    {
        path: "/offers",
        name: "Offers",
        component: Offers,
        meta: {
            middleware: [auth, is_admin, can],
            title: "offers",
            permission: "offers"
        }
    },
    {
        path: "/brands",
        name: "Brands",
        component: Brands,
        meta: {
            middleware: [auth, is_admin, can],
            title: "brands",
            permission: "coupons"
        }
    },
    {
        path: "/regions",
        name: "Regions",
        component: Regions,
        meta: {
            // middleware: [auth, is_admin, can],
            title: "regions",
            permission: "regions"
        }
    },
    {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        meta: {
            // middleware: [auth, is_admin, can],
            title: "notifications",
            permission: "notifications"
        }
    },
    {
        path: "/app-notifications",
        name: "AppNotifications",
        component: AppNotifications,
        meta: {
            // middleware: [auth, is_admin, can],
            title: "app_notifications",
            permission: "notifications"
        }
    },
    {
        path: "/cities",
        name: "Cities",
        component: Cities,
        meta: {
            // middleware: [auth, is_admin, can],
            title: "cities",
            permission: "cities"
        }
    },
    {
        path: "/countries",
        name: "Countries",
        component: Countries,
        meta: {
            middleware: [auth, is_admin, can],
            title: "countries",
            permission: "countries"
        }
    },
    {
        path: "/important_stores",
        name: "ImportantStores",
        component: ImportantStores,
        meta: {
            middleware: [auth, is_admin, can],
            title: "important_stores",
            permission: "countries",
        }
    },
    {
        path: "/ads",
        name: "Ads",
        component: Ads,
        meta: {
            middleware: [auth, is_admin, can],
            title: "ads",
            permission: "ads"
        }
    },
    {
        path: "/p_groups",
        name: "PGroups",
        component: PGroups,
        meta: {
            middleware: [auth, is_admin, can],
            title: "p_groups",
            permission: "p_groups"
        }
    },
    {
        path: "/categories",
        name: "Categories",
        component: Categories,
        meta: {
            middleware: [auth, is_admin, can],
            title: "categories",
            permission: "categories"
        }
    },
    {
        path: "/sub_categories",
        name: "SubCategories",
        component: SubCategories,
        meta: {
            middleware: [auth, is_admin, can],
            title: "sub_categories",
            permission: "sub_categories"
        }
    },
    {
        path: "/c_reasons",
        name: "CReasons",
        component: CReasons,
        meta: {
            middleware: [auth, is_admin, can],
            title: "c_reasons",
            permission: "c_reasons"
        }
    },
    {
        path: "/attribute_sets",
        name: "AttributeSets",
        component: AttributeSets,
        meta: {
            middleware: [auth, is_admin, can],
            title: "attribute_sets",
            permission: "attributes"
        }
    },
    {
        path: "/attributes",
        name: "Attributes",
        component: Attributes,
        meta: {
            middleware: [auth, is_admin, can],
            title: "attributes",
            permission: "attributes"
        }
    },
    {
        path: "/options",
        name: "Options",
        component: Options,
        meta: {
            middleware: [auth, is_admin, can],
            title: "options",
            permission: "options"
        }
    },
    {
        path: "/help",
        name: "Help",
        component: Help,
        meta: {
            middleware: [auth, is_admin, can],
            title: "help",
            permission: "help"
        }
    },
    {
        path: "/sections",
        name: "Sections",
        component: Sections,
        meta: {
            middleware: [auth, is_admin, can],
            title: "sections",
            permission: "sections"
        }
    },
    {
        path: "/posts",
        name: "Posts",
        component: Posts,
        meta: {
            middleware: [auth, is_admin, can],
            title: "posts",
            permission: "posts"
        }
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {middleware: [auth], title: "my_info"}
    },
    {
        path: "/admins",
        name: "Admins",
        component: Admins,
        meta: {
            middleware: [auth, is_admin, can],
            title: "admins",
            permission: "admins"
        }
    },
    {
        path: "/groups",
        name: "Groups",
        component: Groups,
        meta: {
            middleware: [auth, is_admin, can],
            title: "groups",
            permission: "groups"
        }
    },
    {
        path: "/incomes",
        name: "Incomes",
        component: Incomes,
        meta: {
            middleware: [auth, is_admin, can],
            title: "incomes",
            permission: "incomes"
        }
    },
    {
        path: "/delivery_companies",
        name: "DeliveryCompanies",
        component: DeliveryCompanies,
        meta: {
            middleware: [auth, is_admin, can],
            title: "delivery_companies",
            permission: "delivery_companies"
        }
    },
    {
        path: "/packs",
        name: "Packs",
        component: Packs,
        meta: {
            middleware: [auth, is_admin, can],
            title: "packs",
            permission: "packs"
        }
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
            middleware: [auth, is_admin, can],
            title: "settings",
            permission: "settings"
        }
    },
    {
        path: "/about_us",
        name: "About Us",
        component: AboutUs,
        meta: {
            middleware: [auth, is_admin, can],
            title: "about_us",
            permission: "settings"
        }
    },
    {
        path: "/email_templates",
        name: "EmailTemplates",
        component: EmailTemplates,
        meta: {
            middleware: [auth, is_admin, can],
            title: "email_templates",
            permission: "settings"
        }
    },
    {
        path: "/notification_templates",
        name: "NotificationTemplates",
        component: NotificationTemplates,
        meta: {
            middleware: [auth, is_admin, can],
            title: "notification_templates",
            permission: "settings"
        }
    },
    {
        path: "/settings/info",
        name: "Info",
        component: Info,
        meta: {
            middleware: [auth, is_admin, can],
            title: "info",
            permission: "settings"
        }
    },
    {
        path: "/settings/slider",
        name: "Slider",
        component: Slider,
        meta: {
            middleware: [auth, is_admin, can],
            title: "slider",
            permission: "settings"
        }
    },
    {
        path: "/settings/banners",
        name: "Banners",
        component: Banners,
        meta: {
            middleware: [auth, is_admin, can],
            title: "banners",
            permission: "banners"
        }
    },
    {
        path: "/settings/images",
        name: "Images",
        component: Images,
        meta: {
            middleware: [auth, is_admin, can],
            title: "images",
            permission: "settings"
        }
    },
    {
        path: "/settings/payment_gates",
        name: "PaymentGates",
        component: PaymentGates,
        meta: {
            middleware: [auth, is_admin, can],
            title: "payment_gates",
            permission: "settings"
        }
    },
    {
        path: "/collections",
        name: "Collections",
        component: Collections,
        meta: {
            middleware: [auth, is_admin, can],
            title: "collections",
            permission: "collections"
        }
    },
    {
        path: "/invoices",
        name: "Invoices",
        component: Invoices,
        meta: {
            middleware: [auth, is_admin, can],
            title: "invoices",
            permission: "invoices"
        }
    },
    {
        path: "/w_requests",
        name: "WRequests",
        component: WRequests,
        meta: {
            middleware: [auth, is_admin, can],
            title: "w_requests",
            permission: "w_requests"
        }
    },
    {
        path: "/wallet",
        name: "Wallet",
        component: Wallet,
        meta: {
            middleware: [auth, is_admin, can],
            title: "wallet",
            permission: "wallet"
        }
    },
    {
        path: "/home_sections",
        name: "HomeSection",
        component: HomeSection,
        meta: {
            middleware: [auth, is_admin, can],
            title: "home_sections",
            permission: "home_sections"
        }
    },
    {
        path: "/error",
        name: "Error",
        component: Error,
        meta: {title: "error"}
    },
    {
        path: "*",
        name: "ErrorPage",
        component: Error,
        meta: {title: "error"}
    }

];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return document
                .querySelector(to.hash)
                .scrollIntoView({behavior: "smooth"});
        } else {
            return savedPosition || {x: 0, y: 0};
        }
    }
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            to,
            store
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({...context, next: nextMiddleware});
    } else {
        return next();
    }
});

export default router;
